import request from '@/utils/request'


/**
 * 用户行为记录
 */
export function behavior(data) {
    return request({
        url: '/behavior/save',
        method: 'post',
        data
    })
}

/**
 * mysql数据转换
 */
export function mysqlCmdTransfer(data) {
    return request({
        url: '/system/mysqlCmdTransfer',
        method: 'post',
        data
    })
}

