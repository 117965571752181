<template>
  <div class="topNav">
    <div class="navModule">
      <div class="navLogo">
        <router-link to="/">
          <img src="../assets/mekzu_logo_300.png" alt="首页">
        </router-link>
        <el-menu
            router
            :default-active="$route.path"
            mode="horizontal"
            :ellipsis="false"
            background-color="#393D49"
            active-text-color="#009688"
            text-color="#fffc">
          <el-menu-item index="/">
            <router-link to="/">首页</router-link>
          </el-menu-item>
          <el-sub-menu index="2">
            <template #title>加密工具</template>
            <el-menu-item index="/md5"><router-link to="/md5">MD5加密</router-link></el-menu-item>
            <el-menu-item index="/base64"><router-link to="/base64">Base64加解密</router-link></el-menu-item>
          </el-sub-menu>
          <el-menu-item index="/timestamp">
            <router-link to="/timestamp">时间戳转换</router-link>
          </el-menu-item>
          <el-menu-item index="/json">
            <router-link to="/json">JSON格式化</router-link>
          </el-menu-item>
          <el-menu-item index="/barcode">
            <router-link to="/barcode">生成条形码</router-link>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="navLogin">
        <div class="login" v-if="isLogin" @click="showLogin">登录</div>
        <div class="logoff" v-else @click="outLogin"><img src="../assets/user2.png" alt="用户"></div>
      </div>
    </div>
    <login v-if="loginShow" :changeLoginStatus="changeLoginStatus"></login>
  </div>
</template>

<script>
import Login from "@/components/login/Login.vue";
import {ElNotification} from "element-plus";

export default {
  name: "HeaderNav",
  components: {
    Login
  },
  data() {
    return {
      isLogin: true,
      userName: "",
    };
  },
  created() {
    this.matchUserInfo();
  },
  methods: {
    /**
     * 展示登录弹窗
     */
    showLogin() {
      this.$store.commit("changeNeedLogin", true);
    },

    /**
     * 登录后无刷新展示用户信息
     */
    changeLoginStatus(val) {
      if (val) {
        this.userName = val; //this.replaceStr(val, 10);
        this.isLogin = false;
      }
    },

    /**
     * 退出登录
     */
    outLogin() {
      localStorage.clear();
      this.isLogin = true;
      ElNotification({
        title: "成功",
        message: "退出登录成功",
        type: 'success',
      })
    },

    /**
     * matchUserInfo
     */
    matchUserInfo() {

      this.isLogin = false;

      if (!localStorage.getItem("mkz-auth")) {
        //显示登录按钮
        this.isLogin = true;
        return false
      }

      let userinfo = JSON.parse(localStorage.getItem("user-info"));

      this.userName = userinfo.userdata.username;
    },
  },
  computed: {
    loginShow() {
      return this.$store.state.needLogin;
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.topNav {

  background-color: #393D49;

  .navModule {

    display: flex;
    justify-content: space-between;
    padding: 2px 0;

    @media screen and (min-width: 1200px) {
      width: 1200px;
      margin: 0 auto;
    }

    .navLogo {
      display: flex;

      .el-menu--horizontal {
        border-bottom: none;
      }
    }

    .navLogo img {

      @media screen and (min-width: 1200px) {
        width: 120px;
        margin-top: 8px;
      }
    }

    .navLogin {

      .login, .logoff {

        background-color: #009688;
        color: #ffffff;
        cursor: pointer;
        overflow: hidden;
        margin-top: 4px;

        @media screen and (min-width: 1200px) {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          text-align: center;
          line-height: 50px;
        }
      }

      .logoff img {
        width: 30px;
      }
    }
    
  }
}
</style>
