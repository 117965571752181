import request from '@/utils/request'


/**
 * 账号密码注册/登录
 */
export function accountLogin(data) {
    return request({
        url: '/login/accountLogin',
        method: 'post',
        data
    })
}