<template>
  <div class="home">
    <div class="home-content">
      <div style="width: 100%">
        <jsonFormat :jsonString="jsonString"></jsonFormat>
      </div>
      <!-- <div style="width: 300px; margin-left: 30px">
        <toolsList v-show="isShowToolList"></toolsList>
      </div> -->
    </div>
  </div>
</template>

<script>
// import toolsList from "@/components/tools/ToolsList.vue";
import jsonFormat from "@/components/tools/JsonFormat.vue";
export default {
  name: "JsonView",
  components: {
    // toolsList,
    jsonFormat,
  },
  data() {
    return {
      isShowToolList: true,
      jsonString: "",
    };
  },
  created() {
    if (this.$route.query.isShowToolsList == 1) {
      this.isShowToolList = true;
    }
  },
  mounted() {
    // console.log(this.$route.params);
  }
};
</script>
<style scoped lang="scss">
.home {

  // text-align: center;
  .home-content {
    width: 100%;
    display: flex;
  }
}
</style>>