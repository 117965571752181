import { createStore } from 'vuex'

export default createStore({
  state: {
    //false 不需要登录  true 需要登录
    needLogin: false,
    jsonStr: ''
  },
  getters: {
    getNeedLogin: state => state.needLogin
  },
  mutations: {
    /**
     * 修改needLogin
     * @param {*} state 属性
     * @param {*} data 数据
     */
    changeNeedLogin(state, data) {
      state.needLogin = data;
    },
  },
  actions: {

  },
  modules: {
  }
})
