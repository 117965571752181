import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import JsonView from "../views/JsonView.vue";
import BarcodeView from "../views/BarcodeView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/IndexView.vue'),
        meta: {
            title: "首页",
        },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/json',
        name: 'json',
        component: JsonView,
        meta: {
            title: "JSON在线格式化",
        },
    },
    {
        path: '/timestamp',
        name: 'timestamp',
        component: HomeView,
        meta: {
            title: "时间戳在线转换",
            needLogin: true,
        },
    },
    {
        path: '/barcode',
        name: 'barcode',
        component: BarcodeView,
        meta: {
            title: "在线生成条形码",
        },
    },
    {
        path: '/md5',
        name: 'md5',
        component: () => import('../views/Md5View.vue'),
        meta: {
            title: "md5在线加密",
        },
    },
    {
        path: '/base64',
        name: 'base64',
        component: () => import('../views/Base64View.vue'),
        meta: {
            title: "Base64加解密",
        },
    },
    {
        path: '/mysql',
        name: 'mysql',
        component: () => import('../views/MysqlView.vue'),
        meta: {
            title: "Base64加解密",
        },
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
