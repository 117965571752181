<template>
  <div class="home">
    <div class="home-content">
      <div style="width: 80%">
        <timeStamp></timeStamp>
      </div>
      <div style="width: 300px; margin-left: 30px">
        <toolsList v-show="isShowToolList"></toolsList>
      </div>
    </div>
  </div>
</template>

<script>
import timeStamp from "@/components/tools/TimeStamp.vue";
import toolsList from "@/components/tools/ToolsList.vue";
export default {
  name: "HomeView",
  components: {
    timeStamp,
    toolsList,
  },
  data() {
    return {
      isShowToolList: true,
    };
  },
  created() {
    if (this.$route.query.isShowToolsList == 1) {
      this.isShowToolList = true;
    }
  },
};
</script>
<style scoped lang="scss">
.home {
  // text-align: center;
  .home-content {
    margin: 20px auto;
    width: 70%;
    display: flex;
  }
}
</style>>