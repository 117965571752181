<template>
  <div>
    <el-card
      shadow="always"
      style="border-top-color: #009688; border-top-width: 5px"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">快捷工具</el-breadcrumb-item>
        <el-breadcrumb-item>时间戳在线转换</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>

    <el-card class="timestamp-box">
      <el-row :gutter="20">
        <el-col class="timestamp-box-left-title" :span="4">现在：</el-col>
        <el-col :span="20" style="cursor: pointer" @click="moveTimeStamp()">{{
          nowTimeStamp
        }}</el-col>
      </el-row>
      <br />
      <el-row :gutter="20">
        <el-col class="timestamp-box-left-title" :span="4">时间戳：</el-col>
        <el-col :span="20">
          <div style="display: flex">
            <el-input
              style="width: 250px"
              placeholder="请输入时间戳"
              v-model="timeStampInput.timeStampToDateFormatOne"
            />
            <el-button
              color="#009688"
              style="margin: 0 10px"
              @click="changeTime(1)"
              v-behavior="{
                title: '时间戳在线转换',
                name: '时间戳转时间格式',
                type: 'click',
              }"
              >转换</el-button
            >
            <el-input
              style="width: 300px"
              placeholder="--"
              v-model="timeStampInput.DateFormatTotimeStampOne"
            />
          </div>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="20">
        <el-col class="timestamp-box-left-title" :span="4">时间：</el-col>
        <el-col :span="20">
          <div style="display: flex">
            <el-input
              style="width: 250px"
              placeholder="请输入时间"
              v-model="timeStampInput.timeStampToDateFormatTwo"
            />
            <el-button
              color="#009688"
              style="margin: 0 10px"
              @click="changeTime(2)"
              v-behavior="{
                title: '时间戳在线转换',
                name: '时间格式转时间戳',
                type: 'click',
              }"
              >转换</el-button
            >
            <el-input
              style="width: 300px"
              placeholder="--"
              v-model="timeStampInput.DateFormatTotimeStampTwo"
            />
          </div>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="20">
        <el-col class="timestamp-box-left-title" :span="4">北京时间</el-col>
        <el-col :span="20">
          <el-descriptions>
            <el-descriptions-item>
              北京时间是 中国 采用国际时区东八时区的 区时 作为标准时间。
              北京时间并不是北京（东经116.4°）的地方时间，而是东经120°的地方时间，故东经120度地方时比北京的地方时早约14分半钟。</el-descriptions-item
            >
          </el-descriptions>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: "",

      nowTimeStamp: "--", //当前时间戳

      timeStampInput: {
        timeStampToDateFormatOne: "",
        DateFormatTotimeStampOne: "",
        timeStampToDateFormatTwo: "",
        DateFormatTotimeStampTwo: "",
      },
    };
  },
  methods: {
    /**
     * 获取当前时间戳
     */
    getNowTimeStamp() {
      this.nowTimeStamp = Date.parse(new Date()) / 1000;

      this.timeStampInput.timeStampToDateFormatOne = this.nowTimeStamp;

      this.timeStampInput.timeStampToDateFormatTwo = this.getTime(
        this.nowTimeStamp
      );

      //开始执行定时器
      this.timer = setInterval(this.changeTimeStamp, 1000);
    },

    /**
     * 修改当前时间戳
     */
    changeTimeStamp() {
      this.nowTimeStamp++;
    },

    /**
     * 移动到时间戳转时间并自动转换
     */
    moveTimeStamp() {
      this.timeStampInput.timeStampToDateFormatOne = this.nowTimeStamp;
    },

    /**
     * 点击转换
     */
    changeTime(changeType) {
      if (changeType == 1) {
        if (!this.timeStampInput.timeStampToDateFormatOne) {
          this.$message.warn("请输入时间戳", 1);
          return false;
        }

        //转换时间

        this.timeStampInput.DateFormatTotimeStampOne = this.getTime(
          this.timeStampInput.timeStampToDateFormatOne
        );
      }

      if (changeType == 2) {
        if (!this.timeStampInput.timeStampToDateFormatTwo) {
          this.$message.warn("请输入时间", 1);
          return false;
        }

        //转换时间戳

        let time =
          new Date(this.timeStampInput.timeStampToDateFormatTwo).getTime() /
          1000;
        if (isNaN(time)) {
          this.$message.warn("格式有误,请重试", 1);
        } else {
          this.timeStampInput.DateFormatTotimeStampTwo = time;
        }
      }
    },

    // 标准时间转换成年月日时分秒（补0）
    getTime(timestamp) {
      let timeStr = timestamp.toString();

      if (timeStr.length == 10) {
        timestamp *= 1000;
      }

      try {
        //正常执行
        var date = new Date(Number(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear(),
          M =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1,
          D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
          h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
          m =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes(),
          s =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();

        if (
          isNaN(Y) ||
          isNaN(M) ||
          isNaN(D) ||
          isNaN(h) ||
          isNaN(m) ||
          isNaN(s)
        ) {
          this.$message.warn("格式有误,请重试", 1);
          return "";
        } else {
          return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },

  created() {},

  mounted() {
    this.getNowTimeStamp();
  },

  beforeUnmount() {
    //销毁组件前执行销毁定时器
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="scss">
.timestamp-box {
  margin-top: 20px;
  .timestamp-box-left-title {
    text-align: right;
  }
}
</style>